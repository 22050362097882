
html,body {
  height: 100%;
}
body {
  /*min-height: 100%;
  min-height: 100vh;
  */
  
    /*background-image: url('../img/FONDO-01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;*/

  font-family: 'Roboto', sans-serif;
}
.links{
    text-decoration: underline;
    text-decoration-color: orange;
    color: orange;
    font-family: 'Roboto', sans-serif;
    font-size: 8pt;

}

.content{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.spanLRH{
    color: white;
    font-size: 10pt;
}
.accessDiv{
    margin-top: 30%;
}


  input[type="email"] {
    border-bottom: none !important;
    outline: none !important;
  }
  input[type="password"] {
    border-bottom: none !important;
    outline: none !important;
  }
  .iconPositionInput{
    color: #A3A3A9;
    top: 0.7rem !important;
    font-size: 1.4rem !important;
}

nav{
    
    background-color: #212121 !important;
}
.margin-right{
  
  margin-right: 20px;
}
.backgroun-dark{
  background-color: #212121 !important;

 
}


.show-ps{
  width: 150px !important;
  background: white !important;
    border-radius: 10%
    
}

.logoNav{
  background-image: url('../img/logoWhite.png');
  height: 55px;
  width: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
}

.imgNav{
  width: 100%;
  height: 200px;
  background-image: url('../img/ENCABEZADOHOME-01.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.active {
  color: rgb(228, 228, 37);

}


.btnIconLogut{
  top: -13px;
      position: relative;
      color: white;
}

.btnLogut{
  /*border-radius: 100px;*/
      background-color:#212121;
}

.iconsInputs{
  color:  #A3A3A9;
}

/*
scroll style y
*/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background:  #22212F; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9b9a9a7a; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9b9a9a7a; 
}

.circularRosution{
  image-resolution:  850dpi !important;
  object-fit:cover;
  object-position:50% 50%;
}

.sizeGifLoad{
  width: 100px !important;
  height: 100px !important;
}

.centerGif{
  margin: auto 0px;
  width: 100%;
  
}

/*SideNAV*/

.userViewM{
  background: darkslategrey;
}

/*input registro users*/
.inputRegisterUser{
  border-radius: 5px;
    color: black;
   margin: 0 auto;
   margin-bottom: 10px;    
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: 300px;
    height: 46px;
    font-family: 'Roboto', sans-serif !important;
    text-decoration-color: black;
  background-color:#ffffff !important;
}

.contentBtnUsers{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
}