nav{
    display: block;
    background-color: #212121 !important;
}

.navSizeDiv{height:25% !important;}

.body-home{
    background-image: url('../img/FONDO-01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
}

.contentForm-home{
   /*background: red;*/
    height: auto;
    position: absolute;
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    font-family: 'Roboto', sans-serif;
}

.greeting{
    font-weight: 600;
    color: #FBAB7E;
    text-transform: uppercase;
}
.border-10{
    border-radius: 10px;
}
.border-top-10{
    border-radius: 10px 10px 0px 0px;
}
.number-Card{
    font-size: 50pt;
    font-weight: 700;
}

.card-gradient-msn{
    background: linear-gradient(
        45deg, #A8C0FF, #3F2B96);
}

.card-gradient-evi{
    background: linear-gradient(
        45deg, #FF9A8B, #FF6A88);
}

.card-gradient-inci{
    background: linear-gradient(
        45deg, #F7CE68, #FBAB7E);
}

.card-gradient-usu{
    background: linear-gradient(
        45deg, #80D0C7, #0093E9);
}

.sizeCardHome{
    height: 250px !important;
}

